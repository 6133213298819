import { AppRouter } from "./routes";
import { theme } from "./constants/mui-style";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppRouter />
      </ThemeProvider>
      {/*
        <CookieConsentDialog />
        */}
    </>
  );
}

export default App;
